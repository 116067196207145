<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-calendar</v-icon>
        Calendários Mídias Sociais
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title class="mx-3">
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <!-- <v-btn
          @click="createMidiaCalendario"
          align="center"
          color="secondary"
          class="white--text"
        >
          adicionar
        </v-btn> -->
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="midiaCalendarios"
          :items-per-page="10"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToListaPostagemCalendario"
        >
          <template v-slot:item.status="{ item }">
            {{ item.status | statusCalendario }}
          </template>
          <template v-slot:item.inicio="{ item }">
            {{ item.inicio | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.fim="{ item }">
            {{ item.fim | dateFormat("dd/MM/yyyy") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchMidiasSociaisCalendarios } from "@/api/cliente/midias_sociais_calendario.js";
import { mapState } from "vuex";
export default {
  name: "ListaCalendarioMidiaSociais",

  data() {
    return {
      search: "",
      midiaCalendarios: [],
      midiaCalendario: {},
      loading: true,
      dialogMidiaCalendario: false,
      dialogListaPostagem: false,
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    headers() {
      return [
        {
          text: "Data inicio",
          value: "inicio",
        },
        {
          text: "Data final",
          value: "fim",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
  },

  methods: {
    createMidiaCalendario() {
      this.midiaCalendario = {};
      this.dialogMidiaCalendario = true;
    },

    goToListaPostagemCalendario(item) {
      this.$router.push({
        path: `/cliente/midias-sociais/calendario-midias-sociais/${item.id}`,
      });
    },

    getMidiaCalendarios() {
      this.loading = true;
      return fetchMidiasSociaisCalendarios(`?user_id=${this.user_id}`)
        .then((response) => {
          this.midiaCalendarios = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getMidiaCalendarios();
  },
};
</script>

<style></style>
